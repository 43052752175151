<template>
  <v-row justify="center">
    <v-dialog
      v-model="open"
      scrollable
      max-width="760px"
    >
      <v-card class="pa-5">
        <v-card-title>
          {{ label.id ? "Update the label" : "Add new label" }}
        </v-card-title>
        <v-card-text>
          <label-form
            :label="label"
            @closeLabelModal="(open = false), $emit('modalClosed')"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import LabelForm from "./LabelForm.vue";
export default {
  components: { LabelForm },
  props: {
    label: {
      type: Object,
      required: false,
      default: () => {},
    },
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  name: "LabelCreateUpdateModal",
  data() {
    return {
      open: this.openModal || false,
    };
  },
  watch: {
    openModal(newValue) {
      if (newValue) {
        this.open = newValue;
      }
    },
    open(newValue) {
      if (newValue === false) {
        this.$emit("modalClosed");
      }
    },
  },
};
</script>
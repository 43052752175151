<template>
  <form>
    <v-text-field
      label="Label Name"
      v-model="labelFormData.name"
    />
    <v-text-field
      label="Description"
      v-model="labelFormData.description"
    />
    <v-btn
      color="primary"
      @click="createOrUpdateLabel"
    >
      {{ label.id ? "Update" : "Create" }}
    </v-btn>
  </form>
</template>

<script>
export default {
  name: "LabelForm",
  props: {
    label: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      labelFormData: {},
    };
  },
  methods: {
    async createOrUpdateLabel() {
      const response = await this.$store.dispatch(
        "labelCreateOrUpdateAction",
        this.labelFormData
      );
      if ((response && response.status == 200) || response.status == 201) {
        this.labelFormData = {}
        this.$ebus.$emit("newToastMessage", {
          message: response.msg,
        });
        this.$emit("closeLabelModal");
      }
    },
  },
  mounted() {
    this.labelFormData = JSON.parse(JSON.stringify(this.label)) || {};
  },
  watch: {
    "label.id"(newValue) {
      if (newValue) {
        this.labelFormData = JSON.parse(JSON.stringify(this.label));
      } else {
        this.labelFormData = {};
      }
    },
  },
};
</script>
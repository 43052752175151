<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        md="8"
        sm="12"
      >
        <v-row>
          <div
            class="not-found"
            v-if="notes.length === 0"
          >
            No notes found.
          </div>
          <v-col
            v-for="note in notes"
            :key="note.id"
            :cols="true"
          >
            <each-note-card
              :note="note"
              :modal-opened="openModal"
              @onToggleNoteExpansion="(note) => (selectedNote = note)"
              @openEditModal="onOpenEditModal"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="4"
        sm="12"
      >
        <label-list />
      </v-col>
    </v-row>
    <note-modal
      :open-modal="openModal"
      :note="selectedNote"
      @modalClosed="(openModal = false), (selectedNote = {})"
    />
    <note-update
      :note="selectedNoteForUpdate"
      :open-modal="openEditModal"
      @updateModalCollapse="
        (openEditModal = false), (selectedNoteForUpdate = {})
      "
    />
  </v-container>
</template>

<script>
import EachNoteCard from "../note/card/EachNoteCard.vue";
import NoteModal from "../note/NoteModal.vue";
import { mapGetters } from "vuex";
import NoteUpdate from "../note/NoteUpdate.vue";
import LabelList from "./LabelList.vue";

export default {
  name: "AllLabelNoteList",
  components: { EachNoteCard, NoteModal, NoteUpdate, LabelList },
  computed: {
    ...mapGetters(["labelWiseNotes"]),
  },
  data() {
    return {
      notes: [],
      selectedNote: {},
      selectedNoteForUpdate: {},
      openModal: false,
      openEditModal: false,
    };
  },
  mounted() {
    this.notes = this.labelWiseNotes["all"] || [];
  },
  methods: {
    onOpenEditModal(note) {
      this.openEditModal = true;
      this.selectedNoteForUpdate = note;
    },
  },
  watch: {
    "labelWiseNotes.all": {
      handler(newValue) {
        this.notes = newValue;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.not-found {
  margin: 3rem auto;
}
</style>
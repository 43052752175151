<template>
  <div>
    <label-create-update-modal
      :label="selectedLabel"
      :open-modal="openModal"
      @modalClosed="onModalClosed"
    />
    <my-alert ref="confirm" />
    <div class="text-right">
      <v-btn
        small
        color="info"
        @click="openModal = true"
      >
        <v-icon>mdi-plus</v-icon>
        Create
      </v-btn>
    </div>
    <p
      class="text-center"
      v-if="allLabelList.length === 0"
    >
      No label found yet.
    </p>
    <v-list>
      <v-list-item
        v-for="label in allLabelList"
        :key="label.id"
      >
        <v-list-item-avatar>
          <v-icon
            class="grey lighten-1"
            dark
          >
            mdi-label
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ label.name }}</v-list-item-title>
          <v-list-item-subtitle v-html="label.description" />
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            small
            color="primary"
            @click="onEditLabel(label)"
          >
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </v-list-item-action>

        <v-list-item-action>
          <v-btn
            icon
            small
            color="red white--text"
            @click="onDeleteLabel(label)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MyAlert from "../../common/MyAlert.vue";
import LabelCreateUpdateModal from "./LabelCreateUpdateModal.vue";

export default {
  name: "LabelList",
  components: { LabelCreateUpdateModal, MyAlert },
  computed: {
    ...mapGetters(["allLabelList"]),
  },
  data() {
    return {
      selectedLabel: {},
      openModal: false,
    };
  },
  methods: {
    onEditLabel(label) {
      this.selectedLabel = label;
      this.openModal = true;
    },
    async onDeleteLabel(label) {
      this.loading = true;
      if (
        await this.$refs.confirm.open({
          title: "Delete!!",
          description: "Are you sure about delete?",
        })
      ) {
        const response = await this.$store.dispatch("deleteLabelAction", label);
        if (response && response.status === 200) {
          this.loading = false;
        } else {
          this.loading = false;
        }
        this.$ebus.$emit("newToastMessage", {
          message: response.msg,
        });
      } else {
        this.loading = false;
      }
    },
    onModalClosed() {
      this.selectedLabel = {};
      this.openModal = false;
    },
  },
};
</script>
<template>
  <div>
    <all-label-note-list />
  </div>
</template>


<script>
import AllLabelNoteList from '../../components/keep/label/AllLabelNoteList.vue'

export default {
  components: { AllLabelNoteList },
}

</script>

<style scoped>
div {
  margin-top: 14px;
}
</style>